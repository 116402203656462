<template>
    <router-link
        :to="$getHomeRouteLink()"
        :style="{ color }"
        class="brand-logo"
        :title="$t('view_site_platform_vivre')"
    >
        <BaseSvg name="logo" />
    </router-link>
</template>

<script>
export default {
    name: 'Logo',

    props: {
        color: {
            type: String,
            default: '#000',
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./../scss/required";

.brand-logo {
    > svg {
        width: 80px;
        height: 30px;

        @include media-breakpoint-down(lg) {
            width: 30px;
        }
    }
}
</style>
