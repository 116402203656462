var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "brand-logo",
      style: { color: _vm.color },
      attrs: {
        to: _vm.$getHomeRouteLink(),
        title: _vm.$t("view_site_platform_vivre"),
      },
    },
    [_c("BaseSvg", { attrs: { name: "logo" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }